<script>
    import SectionWrappers from "../components/SectionWrapper.svelte";
    import {onDestroy, onMount} from "svelte";
    import SectionHeading from "../components/SectionHeading.svelte";

    import {gsap} from "gsap";
    import ScrollTrigger from "gsap/dist/ScrollTrigger";

    export let data = [];

    gsap.registerPlugin(ScrollTrigger);

    let tl;

    onMount(() => {
        tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#introduction",
                start: "bottom bottom",
                scrub: 1,
                pin: true,
            }
        });

        const roundedLinesTop = gsap.utils.toArray('.rounded-line', document.querySelector('#introToplines'));
        const roundedLinesBottom = gsap.utils.toArray('.rounded-line', document.querySelector('#introBottomlines'));
        const roundedLinesWidths = ["86%", "60%", "95%", "60%"];
        const roundedLinesDuration = [1.75, 1.5, 1.75, 1.5];

        roundedLinesTop.forEach((lines, i) => {
            tl.to(lines, {
                width: roundedLinesWidths[i],
                duration: roundedLinesDuration[i],
                ease: "back.out(1.7)",
                scrollTrigger: {
                    trigger: lines,
                    scrub: 1,
                    invalidateOnRefresh: true
                }
            })
        });

        tl.to(".intro-text", {
            scrollTrigger: {
                trigger: ".intro-text",
                start: "top 80%",
                scrub: 1,
                invalidateOnRefresh: true

            }, // start the animation when ".box" enters the viewport (once)
            y: 0,
            opacity: 1,
            duration: 1.5,
            ease: "Power.out"
        });

        roundedLinesBottom.forEach((lines, i) => {
            tl.to(lines, {
                width: roundedLinesWidths[i],
                duration: roundedLinesDuration[i],
                ease: "back.out(1.7)",
                scrollTrigger: {
                    trigger: lines,
                    scrub: 1,
                    invalidateOnRefresh: true

                }
            })
        });
    })


    onDestroy(() => {
        if (tl !== undefined) {
            tl.scrollTrigger.kill();
        }
    })
</script>
<SectionWrappers sectionName="introduction" sectionColor="bg-light-grey">
    <div slot="background" class="absolute inset-0 flex flex-col justify-between">
        <div id="introToplines">
            <div class="relative w-full h-64">
                <div id="rl1"
                     class="rounded-line absolute h-64 bg-sky rounded-bl-full rounded-tl-full w-0 right-0"></div>
                <div id="rl2"
                     class="rounded-line absolute h-64 bg-cobalt rounded-bl-full rounded-tl-full w-0 right-0"></div>
            </div>
            <div class="relative w-full h-64">
                <div id="rl3"
                     class="rounded-line absolute h-64 bg-lavender rounded-br-full rounded-tr-full w-0 left-0"></div>
                <div id="rl4"
                     class="rounded-line absolute h-64 bg-coral rounded-br-full rounded-tr-full w-0 left-0"></div>
            </div>
        </div>
        <!-- MAIN Content Wrapper -->
        <div class="container m-auto intro-text opacity-0">
            <div class="intro-text__column">
                <SectionHeading
                        sectionHeading={data.sectionHeading}
                        sectionParagraph={data.leadingText}
                />
            </div>
        </div>
        <div id="introBottomlines">
            <div class="relative w-full h-64">
                <div id="rl5"
                     class="rounded-line absolute h-64 bg-sky rounded-bl-full rounded-tl-full w-0 right-0"></div>
                <div id="rl6"
                     class="rounded-line absolute h-64 bg-cobalt rounded-bl-full rounded-tl-full w-0 right-0"></div>
            </div>
            <div class="relative w-full h-64">
                <div id="rl7"
                     class="rounded-line absolute h-64 bg-lavender rounded-br-full rounded-tr-full w-0 left-0"></div>
                <div id="rl8"
                     class="rounded-line absolute h-64 bg-coral rounded-br-full rounded-tr-full w-0 left-0"></div>
            </div>
        </div>
    </div>
</SectionWrappers>

<style lang="scss">
  .intro-text {
    @apply grid grid-cols-8 gap-16;
    transform: translate3d(0px, 50%, 0px);

    @screen sm {
      @apply grid-cols-12;
    }

    @screen md {
      @apply grid-cols-16;
    }

    &__column {
      @apply text-center space-y-8 col-span-6 col-start-2;
      @screen sm {
        @apply col-start-4;
      }

      @screen md {
        @apply col-start-5 col-span-8;
      }
    }
  }
</style>
