<script>
    import {createEventDispatcher, onMount} from "svelte";
    import {gsap} from "gsap";
    import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
    import ScrollToPlugin from "gsap/dist/ScrollToPlugin";

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const dispatch = createEventDispatcher();

    export let scrollContainerXValue = 0;

    let scrollContainer;
    let scrollContainerContent;

    let updateScrollPosition = () => {
        if (scrollContainer !== undefined) {
            gsap.to(scrollContainer, {duration: 0.5, scrollTo: {x: scrollContainerXValue, ease: "power4.out"}});
        }
    }

    $: {
        updateScrollPosition(scrollContainerXValue);
    }

    onMount(() => {

        let maxScrollWidthValue = scrollContainerContent.offsetWidth - scrollContainer.offsetWidth;
        dispatch('maxScrollWidthValueUpdated', maxScrollWidthValue);

        // Recalculate the max value of the range slider when the window is resized
        const calculateSliderRange = () => {
            maxScrollWidthValue = scrollContainerContent.scrollWidth - scrollContainer.offsetWidth;
            dispatch('maxScrollWidthValueUpdated', maxScrollWidthValue);
        }

        calculateSliderRange();

        window.addEventListener('resize', calculateSliderRange);
    })
</script>

<div bind:this={scrollContainer} class="informa-scroll-container" tabindex="-1">
    <div bind:this={scrollContainerContent} class="informa-scroll-container__content">
        <slot></slot>
    </div>
</div>

<style lang="scss">
  .informa-scroll-container {
    width: 100%;
    overflow-x: hidden;
    scrollbar-width: none; /* Hide the scrollbar */

    &::-webkit-scrollbar {
      display: none; /* Hide the scrollbar in Webkit-based browsers */
    }

    &__content {
      height: 100%;
      display: flex;
    }
  }
</style>