<script>
    import SectionWrapper from "../components/SectionWrapper.svelte";
    import SectionHeading from "../components/SectionHeading.svelte";
    import '@splidejs/svelte-splide/css';
    import InformaStatisticItem from "../components/InformaStatisticItem.svelte";
    import ScrollContainerWrapper from "../components/ScrollContainerWrapper.svelte";
    import RangeSlider from "../components/RangeSlider.svelte";

    export let data;
    let rangeSliderName = 'statistics-range-slider';

    let rangeSliderEl;
    let maxScrollValue = 0;
    let scrollContainerXValue = 0;

    let updateMaxScrollWidth = (event) => {
        maxScrollValue = event.detail
    }

    let moveSlider = (event) => {
        scrollContainerXValue = event.detail;
    }

</script>

<SectionWrapper sectionName="grow-with-us" sectionColor="bg-white" sectionFullWidth="true">
    <div id="grow-with-us-wrapper">
        <div class="informa-statistics__heading container m-auto relative p-48 flex-[2]">
            <SectionHeading
                    sectionHeading={data.sectionHeading}
                    sectionParagraph={data.leadingText}
            />
        </div>
        <div class="h-48"></div>

        <div class="informa-statistics__content">
            <ScrollContainerWrapper
                    bind:scrollContainerXValue={scrollContainerXValue}
                    on:maxScrollWidthValueUpdated={updateMaxScrollWidth}
            >
                <!-- statistics -->
                <div class="informa-statistics">
                    {#each data.sectionComponent.informaFacts as {
                        leadText,
                        bigFact,
                        trailingText,
                        image,
                        imageAlt
                    }, index}
                        <InformaStatisticItem {leadText} {bigFact} {trailingText} {image} {index} {imageAlt}/>
                    {/each}
                    <div class="informa-statistics__spacer-block"></div>
                </div>
            </ScrollContainerWrapper>

            <div class="h-24 md:h-40"></div>

            <div class="container m-auto px-32">
                <RangeSlider on:rangeValueUpdated={moveSlider} bind:inputMaxValue={maxScrollValue}
                             inputValue={scrollContainerXValue} bind:this={rangeSliderEl}
                             rangeSliderName={rangeSliderName}/>
            </div>
        </div>

        <div class="h-80"></div>
    </div>
</SectionWrapper>
<style lang="scss">

  .informa-statistics {
    @apply flex gap-64;
    transform: translateX(50vw);
    &__spacer-block {
      min-width: 50vw;
    }
  }

</style>