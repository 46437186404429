<script>
    import decodeHtmlEntities from "../helpers/decodeHtmlEntities.js";

    export let sectionHeading = '';
    export let sectionParagraph = '';
    export let textWhite = false;
    export let textSmaller = false;
</script>
<div class="section-content {textWhite ? 'section-content--text-white' : ''} {textSmaller ? 'section-content--smaller' : ''}">
    <h2 class="section-content__heading">{@html decodeHtmlEntities(sectionHeading)}</h2>
    {#if sectionParagraph !== ''}<p class="section-content__paragraph">{@html decodeHtmlEntities(sectionParagraph)}</p>{/if}
</div>
<style lang="scss">
  .section-content {

    @apply text-center space-y-8;

    $self: &;

    &__heading {
      @apply font-aleo-regular text-25px;

      @screen sm {
        @apply text-40px
      }

      @screen lg {
        @apply text-50px
      }
    }

    &__paragraph {
      @apply text-20px font-aleo-light max-w-3xl m-auto;

      @screen sm {
        @apply text-30px;
      }

      @screen lg {
        @apply text-36px;
      }
    }

    &--text-white {
      color: var(--color-white);
    }

    &--smaller {
      #{$self}__heading {
        @apply text-20px;
        @screen sm {
          @apply text-30px
        }

        @screen lg {
          @apply text-36px
        }
      }

      #{$self}__paragraph {
        @apply text-16px;

        @screen sm {
          @apply text-20px;
        }

        @screen lg {
          @apply text-25px;
        }
      }
    }
  }
</style>