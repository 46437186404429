<script>
    import {onMount} from "svelte";
    import viewport from '../../helpers/intersectionObserver';

    let heroVideo;
    export let sectionVideo
    let videoPoster
    let videoAsset
    let resizeVideo;

    switch (sectionVideo) {
        case 'splash-intro':
            videoPoster = 'informa-montage-hero'
            videoAsset = 'informa-montage-hero-2023'
            break;
        case 'ingredients':
            videoPoster = 'informa-ingredients-2024'
            videoAsset = 'informa-ingredients-2024'
            break;
        default:
            videoPoster = 'informa-ingredients'
            videoAsset = 'informa-ingredients'
    }

    /* define our wide breakpoint for the desktop video - this must match the CSS and preloads! */
    const WIDE_VIEWPORT = 480;

    /* define a match on the media query for us to share logic with CSS in JS */
    let wideViewport
    /* define a minimum downlink bandwidth to show the video */

    const MIN_DOWNLINK = 1; // Slow 3G ~~ 0.4, Fast 3G ~~ 1.4

    resizeVideo = (wideViewport) => {

        let videoEl = heroVideo;
        // default to mobile video source and poster
        let src = videoEl.dataset.mobileSrc;
        let srcWebm = videoEl.dataset.mobileSrcWebm;
        let poster = videoEl.dataset.mobilePoster;
        let widthDisplay = 'MOBILE';
        if (wideViewport.matches) { // override for desktop if the media query for a wide vieport matches
            src = videoEl.dataset.desktopSrc;
            poster = videoEl.dataset.desktopPoster;
            srcWebm = videoEl.dataset.desktopSrcWebm;
            widthDisplay = 'DESKTOP';
        }
        let downlink = MIN_DOWNLINK;
        try {
            downlink = navigator.connection.downlink;
        } catch (e) {
            //console.log(`Unable to determine downlink`)
        }
        if (videoEl.src !== src) { // only override values if they differ
            if (downlink >= MIN_DOWNLINK) {

                // we also need to set the video poster
                videoEl.poster = poster;

                // we need to add a source tag to videoEl for webm
                let webmSource = document.createElement('source');
                webmSource.src = srcWebm;
                webmSource.type = 'video/webm';
                videoEl.appendChild(webmSource);

                let source = document.createElement('source');
                source.src = src;
                source.type = 'video/mp4';
                videoEl.appendChild(source);

                widthDisplay += " - FAST";
            } else {
                widthDisplay += " - SLOW";
            } videoEl.poster = poster;
        }
    }

    onMount(() => {
        wideViewport = window.matchMedia(`(min-width: ${WIDE_VIEWPORT}px)`)

        wideViewport.addEventListener('change', resizeVideo)
    })

</script>
<svelte:head>
    <!-- viewport-dependent video poster preloads -->
    <link rel="preload" href="/assets/videos/{videoPoster}-poster.jpg" as="image" media="all and (min-width:480px)">
    <link rel="preload" href="/assets/videos/{videoPoster}-poster-mobile.jpg" as="image" media="all and (max-width:480px)">
</svelte:head>
<video use:viewport
       on:enterViewport={() => resizeVideo(wideViewport)}
       on:exitViewport={() => null}
       bind:this={heroVideo}
       preload="none"
       class="lazy hero-video object-cover h-full w-full full-width-video" autoplay playsinline loop muted
       data-desktop-src="/assets/videos/{videoAsset}.mp4"
       data-desktop-src-webm="/assets/videos/{videoAsset}.webm"
       data-desktop-poster="/assets/videos/{videoPoster}-poster.jpg"
       data-mobile-src="/assets/videos/{videoAsset}-mobile.mp4"
       data-mobile-src-webm="/assets/videos/{videoAsset}-mobile.webm"
       data-mobile-poster="/assets/videos/{videoPoster}-poster-mobile.jpg"
       aria-description="A short video montage of staff at the informa offices."
       tabindex="-1"
>
</video>