<script>
    import SplashIntro from "$lib/sections/SplashIntro.svelte";
    import IntroductionText from "$lib/sections/IntroductionText.svelte";
    import InformaStories from "$lib/sections/InformaStories.svelte";
    import InformaIngredients from "$lib/sections/InformaIngredients.svelte";
    import InformaBenefits from "$lib/sections/InformaBenefits.svelte";
    import InformaCareers from "$lib/sections/InformaCareers.svelte";
    import InformaGraduates from "$lib/sections/InformaGraduates.svelte";
    import InformaGrowthWithUs from "$lib/sections/InformaGrowthWithUs.svelte";
    import RoundedLines from "$lib/components/RoundedLines.svelte";
    import InformaSocialMedia from "$lib/sections/InformaSocialMedia.svelte";
    import InformaContctUs from "$lib/sections/InformaContactUs.svelte";

    import {onDestroy, onMount} from "svelte";
    import {debounce} from "$lib/helpers/debounce.js";
    import {browser} from "$app/environment";
    import {beforeNavigate} from "$app/navigation";

    let showPage = false;
    let key = 0;
    export let data;


    function remountAll() {
        key = key + 1;
    }

    onMount(async () => {
        if (data !== undefined) {
            showPage = true;
        }

        window.addEventListener('resize', debounce(remountAll, 250));

        setTimeout(() => {
            const hash = window.location.hash.replace('#', '');
            if (hash) {
                const sectionElement = document.getElementById(hash);
                sectionElement.scrollIntoView();
            }
        }, 250);
    })

    onDestroy(() => {
        if (browser) {
            window.removeEventListener('resize', debounce(remountAll, 250));
        }
    });

    beforeNavigate(async () => {
        const headerNavLinks = document.querySelectorAll('.header-nav__link');
        headerNavLinks.forEach((link) => {
            link.classList.remove('active');
        });
    });

</script>

<main class="main-wrapper">
    <SplashIntro />
    {#if data.content }
    <IntroductionText data={data.content['intro-text']}/>
    <InformaStories data={data.content['informa-stories']} />
    <InformaIngredients data={data.content['informa-ingredients']} />
    <InformaBenefits data={data.content['informa-benefits']} />
    <InformaCareers data={data.content['informa-careers']} />
    <InformaGraduates data={data.content['graduate-fellowship-scheme']} />
    <InformaGrowthWithUs data={data.content['come-grow-with-us']} />
    <RoundedLines/>
    <InformaSocialMedia data={data.content['linkedin-feed']} />
    <InformaContctUs data={data.content['contact-us']} />
    {/if}
</main>


