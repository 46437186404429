<script>
    import {closingVideoModal, openVideoModal, videoEmbedCode} from "../stores/InformaAppStore.js";

    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let videoTitle = '';
    export let vimeoVideoID = '';
    export let videoThumbnail = '';
    export let jobTitle = '';

    export let isActive = false;


    function playVideo() {
        dispatch('playVideo', { vimeoVideoID });
    }

</script>
<article class="story-card">
    {#if vimeoVideoID !== ''}
        <button class="btn-reset story-card__button" aria-label="Watch: {videoTitle}" on:click="{playVideo}"></button>
    {/if}
    <div class="story-card__video">
        <div class="aspect-photo flex items-end justify-start relative p-8">
            {#if vimeoVideoID !== ''}
                <svg class="story-card__play-icon" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 76 75.999">
                    <path
                          d="M16692,76a37.825,37.825,0,0,1-21.246-6.49,38.086,38.086,0,0,1-13.766-16.719,37.963,37.963,0,0,1,3.5-36.037,38.107,38.107,0,0,1,16.719-13.768,37.942,37.942,0,0,1,36.037,3.5,38.108,38.108,0,0,1,13.77,16.719,37.947,37.947,0,0,1-3.5,36.037,38.135,38.135,0,0,1-16.719,13.768A37.763,37.763,0,0,1,16692,76Zm-9.758-55.459h0V55.972l30.813-17.715-30.812-17.717Z"
                          transform="translate(-16654)" fill="#fff" opacity="0.5"/>
                </svg>
            {/if}
            <div class="absolute inset-0 overflow-hidden" class:story-card--active={isActive}>
                <img loading="lazy" class=" story-card__cover-image" src="{videoThumbnail}" alt="{videoTitle}">
            </div>
        </div>
    </div>
    <div class="story-card__content">
        <div class="space-y-4 text-center">
            <h2 class="text-sky text-[18px] lg:text-[22px] font-aleo-bold leading-[1]">{videoTitle}</h2>
            <p class="text-[14px] lg:text-[18px] font-aleo-regular leading-[1.25]">{jobTitle}</p>
        </div>
    </div>
</article>
<style lang="scss">
  .story-card {

    $self: &;

    @apply col-span-1 relative;

    &__button {
      position: absolute;
      inset: 0;
      width: 100%;
      z-index: 2;

      &:focus {
        box-shadow: 0 0 0 2px var(--color-sky);
      }

      &:hover, &:focus {
        + #{$self}__video #{$self}__play-icon {
          opacity: 0;
        }

        + #{$self}__video #{$self}__cover-image {
          transform: scale(1.1);
        }

        + #{$self}__video + #{$self}__content h2 {
          text-decoration: underline;
        }
      }
    }

    &__cover-image {
      @apply h-full w-full object-cover object-top;
      transition: all 500ms ease-in-out;
    }

    &__content {
      @apply pt-16 flex flex-col justify-center space-y-4;
      h2 {
        transition: all 500ms ease-in-out;
      }
    }

    &__play-icon {
      @apply w-24 md:w-32 relative;
      z-index: 1;
      transition: all 500ms ease-in-out;
    }

    &--active {
      @apply border-4 border-sky;
    }
  }
</style>
