<script>
    import SectionWrapper from "../components/SectionWrapper.svelte";
    import SectionHeading from "../components/SectionHeading.svelte";
    import {onDestroy, onMount} from "svelte";
    import {gsap} from "gsap";
    import ScrollTrigger from "gsap/dist/ScrollTrigger";

    gsap.registerPlugin(ScrollTrigger);

    export let data;
    let tl;

    onMount(() => {

        let leftBars = gsap.utils.toArray(".js-left-bar");
        let rightBars = gsap.utils.toArray(".js-right-bar");

        tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#informa-graduates",
                start: "center center",
                end: `+=${window.innerHeight + (window.innerHeight)}`,
                fastScrollEnd: true,
                toggleActions: "play none none none",
                scrub: 1,
                pin: true,
            }
        })
        tl.to(leftBars, {
            opacity: 1,
            duration: 1,
            ease: "power2.out",
            stagger: 0.9,
        })
        tl.to(rightBars, {
            opacity: 1,
            duration: 1,
            ease: "power2.out",
            stagger: 0.9,
        }, "<")
        tl.to(leftBars, {
            width: "110%",
            duration: 1,
            ease: "power2.out",
            stagger: 0.9,
        })
        tl.to(rightBars, {
            width: "110%",
            duration: 1,
            ease: "power2.out",
            stagger: 0.9,
        }, "<")
        tl.to(leftBars, {
            x: "-310%",
            duration: 1,
            ease: "power2.out",
            stagger: 0.9,
        })
        tl.to(rightBars, {
            x: "310%",
            duration: 1,
            ease: "power2.out",
            stagger: 0.9,
        }, "<")
        tl.to('.js-bars-wrapper', {
            display: 'none',
            duration: 1,
            ease: "power2.out",
        })
        tl.to('.js-graduate-text', {
            opacity: 1,
            duration: 1,
            ease: "power2.out",
        })
        tl.to('.js-graduate-text', {
            opacity: 1,
            duration: 1,
        }, '+=5')

    });

    onDestroy(() => {
        if (tl !== undefined) {
            tl.scrollTrigger.kill();
        }
    })

</script>

<SectionWrapper sectionName="informa-graduates" sectionColor="bg-light-grey" sectionFullWidth="true">

    <div class="bg-indigo flex items-stretch h-screen relative sm:h-[280px]" id="">

        <div class="js-graduate-text container m-auto relative py-64 px-48 flex-[2] text-center space-y-16 opacity-0">
            <SectionHeading
                    sectionHeading={data.sectionHeading}
                    sectionParagraph={data.leadingText}
                    textWhite="true"
                    textSmaller="true"
            />
            {#each data.ctaButtons as buttons }
                <a href={buttons.buttonUrl} role="button" target="_blank">{buttons.buttonLabel}</a>
            {/each}

        </div>
        <div class="js-bars-wrapper absolute inset-0 flex items-center justify-center">
            <div class="h-[280px] w-full flex flex-col items-end">
                <div class="js-left-bar bg-coral rounded-full h-[25%] w-[70px] relative opacity-0">&nbsp;</div>
                <div class="js-left-bar bg-sky rounded-full h-[25%] w-[70px] relative opacity-0">&nbsp;</div>
                <div class="js-left-bar bg-cobalt rounded-full h-[25%] w-[70px] relative opacity-0">&nbsp;</div>
                <div class="js-left-bar bg-lavender rounded-full h-[25%] w-[70px] relative opacity-0">&nbsp;</div>
            </div>
            <div class="h-[280px] w-full flex flex-col items-start">
                <div class="js-right-bar bg-coral rounded-full h-[25%] w-[70px] relative opacity-0">&nbsp;</div>
                <div class="js-right-bar bg-sky rounded-full h-[25%] w-[70px] relative opacity-0">&nbsp;</div>
                <div class="js-right-bar bg-cobalt rounded-full h-[25%] w-[70px] relative opacity-0">&nbsp;</div>
                <div class="js-right-bar bg-lavender rounded-full h-[25%] w-[70px] relative opacity-0">&nbsp;</div>
            </div>
        </div>
    </div>

</SectionWrapper>
<style lang="scss">
  .js-left-bar {
    margin-right: -35px;
    min-width: 70px;
  }

  .js-right-bar {
    margin-left: -35px;
    min-width: 70px;
  }
</style>
