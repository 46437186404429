<script>
    import {closingVideoModal, openVideoModal, videoEmbedCode} from "../stores/InformaAppStore.js";

    export let videoTitle = '';
    export let vimeoVideoID = '';
    export let videoThumbnail = '';
    export let jobTitle = '';
    export let subjectQuote = '';

    function openModal() {
        // Set the values for the video
        if (vimeoVideoID !== '' && $closingVideoModal === false) {
            videoEmbedCode.set(vimeoVideoID)
            openVideoModal.set(true)
        }
    }

</script>
<article class="hero-story-card">
    <button class="btn-reset hero-story-card__button" aria-label="Watch: {videoTitle}" on:click="{openModal}"></button>
    <div class="hero-story-card__video">
        <div class="aspect-photo flex items-center justify-center relative">
            <svg class="hero-story-card__play-icon" xmlns="http://www.w3.org/2000/svg" height="75.999"
                 viewBox="0 0 76 75.999">
                <path id="Subtraction_16" data-name="Subtraction 16"
                      d="M16692,76a37.825,37.825,0,0,1-21.246-6.49,38.086,38.086,0,0,1-13.766-16.719,37.963,37.963,0,0,1,3.5-36.037,38.107,38.107,0,0,1,16.719-13.768,37.942,37.942,0,0,1,36.037,3.5,38.108,38.108,0,0,1,13.77,16.719,37.947,37.947,0,0,1-3.5,36.037,38.135,38.135,0,0,1-16.719,13.768A37.763,37.763,0,0,1,16692,76Zm-9.758-55.459h0V55.972l30.813-17.715-30.812-17.717Z"
                      transform="translate(-16654)" fill="#fff" opacity="0.5"/>
            </svg>
            <div class="absolute inset-0 overflow-hidden">
                <img loading="lazy" class="hero-story-card__cover-image" src="{videoThumbnail}" alt="{videoTitle}">
            </div>
        </div>
    </div>
    <div class="hero-story-card__content">
        <div class="col-span-6 sm:col-span-8 md:col-start-1 md:col-span-5">
            <div class="text-center text-20px md:text-[23px] lg:text-30px text-dark-grey font-aleo-italic">
                &#8220;{subjectQuote}&#8221;
            </div>
            <div class="h-40"></div>
            <div class="space-y-4 text-center">
                <h2 class="text-sky text-[18px] lg:text-[22px] font-aleo-bold leading-[1]">{videoTitle}</h2>
                <p class="text-[14px] lg:text-[16px] font-aleo-regular leading-[1.25]">{jobTitle}</p>
            </div>
        </div>
    </div>
</article>
<style lang="scss">
  .hero-story-card {

    $self: &;

    @apply col-span-1 xs:col-span-2 md:col-span-4 grid md:grid-cols-2 relative;
    background-color: white;

    &__button {
      position: absolute;
      inset: 0;
      width: 100%;
      z-index: 2;

      &:focus {
        box-shadow: 0 0 0 2px var(--color-sky);
      }

      &:hover, &:focus {
        + #{$self}__video #{$self}__play-icon {
          opacity: 0;
        }

        + #{$self}__video #{$self}__cover-image {
          transform: scale(1.2);
        }

        + #{$self}__video + #{$self}__content h2 {
          text-decoration: underline;
        }
      }
    }

    &__content {
      @apply p-16 md:p-40 grid grid-cols-6 md:grid-cols-5 gap-16 sm:gap-24 md:gap-32 lg:gap-40;

      @screen lg {
        @apply py-64 px-80;
      }

      h2 {
        transition: all 500ms ease-in-out;
      }
    }

    &__cover-image {
      @apply h-full w-full object-cover;
      transition: all 500ms ease-in-out;
    }

    &__play-icon {
      @apply w-24 sm:w-32 md:w-64;
      z-index: 1;
      transition: all 500ms ease-in-out;
    }

  }
</style>
