<script>
    import {onMount} from "svelte";
    import {gsap} from "gsap";
    import decodeHtmlEntities from "../helpers/decodeHtmlEntities";
    export let jsonPath = '';
    export let heading = '';
    export let copy = ''

    let lottie;

    // To stop lottie populating the same div
    const uniqId = (Math.random() * 10e15).toString(16);

    onMount(async () => {

        const module = await import('lottie-web');
        lottie = module;

        const anim = lottie.loadAnimation({
            container: document.getElementById(uniqId), // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: jsonPath // the path to the animation json
        });

        anim.addEventListener('DOMLoaded', function(e) {
            anim.playSegments([[0,24], [25,120]], true); // run intro animation
        });

    });
</script>
<div class="benefits-grid-item">
    <div id="{uniqId}" class="benefits-grid-item__animation"></div>
    <div class="space-y-8">
        <h3 class="benefits-grid-item__heading">{decodeHtmlEntities(heading)}</h3>
        <p class="benefits-grid-item__copy">{@html decodeHtmlEntities(copy)}</p>
    </div>
</div>

<style lang="scss">
    .benefits-grid-item {
      @apply text-center space-y-16 col-span-1;

      &:last-child {

        @screen sm {
          justify-self: center;
          width: calc(50%);
          grid-column-start: span 2;
        }

        @screen md {
          width: auto;
          justify-self: initial;
          grid-column-start: initial;
        }
      }

      &__heading {
        @apply font-heading text-20px lg:text-25px;
      }

      &__copy {
        @apply text-16px lg:text-20px;
      }

      &__animation {
        @apply px-48 h-80;
      }
    }
</style>