<script>
    import decodeHtmlEntities from "../helpers/decodeHtmlEntities";

    export let copy = '';
    export let image = ''
</script>
<article class="bg-white p-16 lg:p-24 space-y-16 h-full">
    <div class="relative overflow-hidden aspect-video">
        <div class="inset-0 absolute">
            <img loading="lazy" class="object-cover h-full w-full" src="{image}" title="" alt="">
        </div>
    </div>
    <div class="flex space-x-16">
        <span class="flex-1 text-14px lg:text-16px h-[62px] lg:h-[62px]">
            {@html decodeHtmlEntities(copy)}
        </span>
    </div>
</article>