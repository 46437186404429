<script>
    import {onMount, onDestroy} from "svelte";
    import StoryCard from "$lib/components/StoryCard.svelte";
    import { browser } from '$app/environment'
    import { storiesModalVideoEmbedCode, storiesGalleryIsVisible } from "$lib/stores/InformaAppStore.js";
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let data;

    // -- Pagination
    let totalPages;
    let currentPage = 1;
    let paginationLimit = 2;
    let paginatedItems;
    let paginationStartOffset = 0;
    let paginationEndOffset = 0;
    let previousBtnDisabled = false;
    let nextBtnDisabled = false;

    $: {
        totalPages = data === undefined ? 0 : Math.ceil(data.length / paginationLimit)
        paginatedItems = data === undefined ? [] : data.slice(paginationStartOffset, paginationEndOffset)
        previousBtnDisabled = currentPage === 1
        nextBtnDisabled = currentPage === totalPages
    }
    // -- Items layout
    const breakpoints = {
        xs: 360,
        sm: 768,
        md: 1280,
        lg: 1920,
    };

    const checkBreakpoint = () => {
        const windowWidth = window.innerWidth;

        paginationStartOffset = 0
        currentPage = 1

        if (windowWidth <= breakpoints.xs) {
            paginationLimit = 2;
            paginationEndOffset = 2;
        } else if (windowWidth <= breakpoints.sm) {
            paginationLimit = 2
            paginationEndOffset = 2;
        } else if (windowWidth <= breakpoints.md) {
            paginationLimit = 4
            paginationEndOffset = 4
        } else {
            paginationLimit = 6
            paginationEndOffset = 6
        }
    };

    let timeout;

    const onResize = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            checkBreakpoint();
        }, 25); // Debounce time
    };

    onMount(() => {
        window.addEventListener('resize', onResize);
        checkBreakpoint(); // Initial check
    });

    onDestroy(() => {
        if (browser) {
            window.removeEventListener('resize', onResize);
        }
    });

    const backButtonEvent = () => {
        dispatch('backButtonEvent', {});
    }

    const playVideo = (event) => {
        // Set the values for the video
        storiesModalVideoEmbedCode.set(event.detail.vimeoVideoID)
    }

    const previousPage = () => {
        paginationStartOffset = paginationStartOffset - paginationLimit;
        paginationEndOffset = paginationEndOffset - paginationLimit;
        if( currentPage !== 0 ) currentPage--
    }

    const nextPage = () => {
        paginationStartOffset = paginationStartOffset + paginationLimit;
        paginationEndOffset = paginationEndOffset + paginationLimit;
        if( currentPage !== totalPages ) currentPage++
    }

    storiesGalleryIsVisible.subscribe(value => {
        if(!value) {
            setTimeout(() => {
                checkBreakpoint();
            }, 2000)
        }
    });
</script>
<section class="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-16">
    {#each paginatedItems as {
        videoTitle,
        vimeoVideoID,
        videoThumbnail,
        jobTitle,
        subjectQuote
    }, i}
        <div class="col-span-1">
            <StoryCard {videoTitle} {vimeoVideoID} {videoThumbnail} {jobTitle} on:playVideo={playVideo} isActive="{$storiesModalVideoEmbedCode === vimeoVideoID}"/>
        </div>
    {/each}
</section>
<nav class="flex flex-col md:flex-row justify-between items-center gap-24 pb-32">
    <div class="order-2 md:order-none">
        <button class="btn-text" on:click={backButtonEvent}>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6661 6.66513H4.54191L8.94374 2.27664C9.19491 2.02546 9.33602 1.6848 9.33602 1.32958C9.33602 0.974363 9.19491 0.633696 8.94374 0.38252C8.69256 0.131344 8.35189 -0.00976562 7.99668 -0.00976562C7.64146 -0.00976562 7.30079 0.131344 7.04961 0.38252L0.380176 7.05196C0.258738 7.17882 0.163545 7.3284 0.10006 7.49214C-0.0333532 7.81689 -0.0333532 8.18114 0.10006 8.5059C0.163545 8.66963 0.258738 8.81922 0.380176 8.94608L7.04961 15.6155C7.17362 15.7405 7.32115 15.8398 7.48369 15.9075C7.64624 15.9752 7.82059 16.0101 7.99668 16.0101C8.17276 16.0101 8.34711 15.9752 8.50966 15.9075C8.6722 15.8398 8.81973 15.7405 8.94374 15.6155C9.06876 15.4915 9.16799 15.344 9.23571 15.1814C9.30343 15.0189 9.3383 14.8445 9.3383 14.6685C9.3383 14.4924 9.30343 14.318 9.23571 14.1555C9.16799 13.9929 9.06876 13.8454 8.94374 13.7214L4.54191 9.33291H14.6661C15.0199 9.33291 15.3592 9.19237 15.6093 8.94222C15.8595 8.69207 16 8.35279 16 7.99902C16 7.64525 15.8595 7.30597 15.6093 7.05582C15.3592 6.80566 15.0199 6.66513 14.6661 6.66513Z" fill="#002244"/>
            </svg>
            <span class="underline">Back</span>
        </button>
    </div>
    <!-- List here -->
    {#if totalPages >= 1}
    <ul class="flex font-aleo-regular items-center gap-16 order-1 md:order-none">
        <li>
            <button disabled={previousBtnDisabled} class="secondary hover:border-2 border-indigo p-0 m-auto w-auto splash-intro__arrow rotate-90" aria-label="Previous" on:click={previousPage}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7"/></svg>
            </button>
        </li>
        <li class="text-20px">Page { currentPage } / { totalPages }</li>
        <li>
            <button disabled={nextBtnDisabled} class="secondary hover:border-2 border-indigo p-0 m-auto w-auto splash-intro__arrow -rotate-90" aria-label="Next" on:click={nextPage}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7"/></svg>
            </button>
        </li>
    </ul>
    {/if}
</nav>
