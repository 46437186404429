<script>
    import SectionWrapper from "./SectionWrapper.svelte";
    import {gsap} from "gsap";
    import {onDestroy, onMount} from "svelte";

    let tl;

    onMount(() => {
        tl = gsap.timeline({
            scrollTrigger: {
                trigger: '#social-media-divider',
                start: 'top bottom',
                end: 'center center',
                scrub: 1,
            }
        });

        const roundedLines = gsap.utils.toArray('.rounded-lines', document.querySelector('#socialMedialines'));
        const roundedLinesWidths = ["86%", "60%", "95%", "60%"];
        const roundedLinesDuration = [1.75, 1.5, 1.75, 1.5];

        roundedLines.forEach((lines, i) => {
            tl.to(lines, {
                width: roundedLinesWidths[i],
                duration: roundedLinesDuration[i],
                ease: "back.out(1.7)",
            }, '-=0.5')
        });
    })

    onDestroy(() => {
        if (tl !== undefined) {
            tl.scrollTrigger.kill();
        }
    })
</script>

<SectionWrapper sectionName="social-media-divider" sectionColor="bg-light-grey" sectionFullWidth="true"
                sectionHeightAuto="true">
    <div id="socialMedialines" class="relative z-10">
        <div class="h-1/2 bg-white absolute inset-0"></div>
        <div class="relative w-full h-64">
            <div class="rounded-lines absolute h-64 bg-sky rounded-bl-full rounded-tl-full w-0 right-0"></div>
            <div class="rounded-lines absolute h-64 bg-cobalt rounded-bl-full rounded-tl-full w-0 right-0"></div>
        </div>
        <div class="relative w-full h-64">
            <div class="rounded-lines absolute h-64 bg-lavender rounded-br-full rounded-tr-full w-0 left-0"></div>
            <div class="rounded-lines absolute h-64 bg-coral rounded-br-full rounded-tr-full w-0 left-0"></div>
        </div>
    </div>
</SectionWrapper>