<script>
    import { createEventDispatcher } from 'svelte';
    import {gsap} from "gsap";
    import { onMount } from 'svelte';

    const dispatch = createEventDispatcher();

    let inputValue = 0;

    export let rangeSliderName = '';
    export let inputMaxValue = 0;

    let rangeInput;

    let moveSlider = () => {
        const value = rangeInput.value;
        dispatch('rangeValueUpdated', value);
    }

    let moveSliderWithKeyboard = (event) => {
        // Check if the user pressed an arrow key
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            // Calculate the current value and range of the input
            const currentValue = parseFloat(rangeInput.value);
            const minValue = parseFloat(rangeInput.min);
            const maxValue = parseFloat(rangeInput.max);

            // Calculate the step size based on the range and number of steps
            const numSteps = 10;
            const stepSize = (maxValue - minValue) / numSteps;

            // Calculate the new         value based on the arrow key and step size
            let newValue = currentValue;
            if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
                newValue = Math.min(currentValue + stepSize, maxValue);
            } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
                newValue = Math.max(currentValue - stepSize, minValue);
            }

            // Update the value of the input and prevent the default action
            rangeInput.value = newValue;
            inputValue = newValue
            event.preventDefault();
            dispatch('rangeValueUpdated', inputValue);
        }
    }

</script>

<input aria-label="Explore informa" bind:this={rangeInput} on:input={moveSlider} on:keydown={moveSliderWithKeyboard} class="informa-range-slider" type="range" min="0" max={inputMaxValue} value="0" id={rangeSliderName}>

<style lang="scss">

  :root {
    --input-range-width: 100%;
    --input-range-height: 2px;
    --input-range-box-shadow: none;
    --input-range-background: var(--color-light-grey-dark);
    --input-range-border-radius: none;
    --input-range-border: none;
    --input-range-thumb-box-shadow: none;
    --input-range-thumb-border: none;
    --input-range-thumb-height: 3rem;
    --input-range-thumb-width: 3rem;
    --input-range-thumb-border-radius: 10rem;
    --input-range-thumb-background: var(--color-indigo);
    --input-range-cursor: pointer;
    --input-range-svg-arrows: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 7l-5 5l5 5m6-10l5 5l-5 5"/></svg>');
  }

  input.informa-range-slider[type=range] {
    -webkit-appearance: none;
    margin: 18px 0;
    height: 67px;
    width: var(--input-range-width);
    background-color: transparent;
    color: white;

    &:focus {
      height: 67px;
      padding: 2rem 1rem;
      border-radius: 10rem;
      border-color: var(--color-indigo);

      &::-webkit-slider-runnable-track {
        background: var(--color-dark-grey);
      }

      &::-ms-fill-lower {
        background: var(--color-dark-grey);
      }

      &::-ms-fill-upper {
        background: var(--color-dark-grey);
      }
    }

    &::-webkit-slider-runnable-track {
      width: var(--input-range-range-width);
      height: var(--input-range-height);
      cursor: var(--input-range-cursor);
      box-shadow: var(--input-range-box-shadow);
      background: var(--input-range-background);
      border-radius: var(--input-range-border-radius);
      border: var(--input-range-border);
    }

    &::-webkit-slider-thumb {
      box-shadow: var(--input-range-thumb-box-shadow);
      border: var(--input-range-thumb-border);
      height: var(--input-range-thumb-height);
      width: var(--input-range-thumb-width);
      border-radius: var(--input-range-thumb-border-radius);
      background: var(--input-range-thumb-background) var(--input-range-svg-arrows) no-repeat center;
      cursor: var(--input-range-cursor);
      -webkit-appearance: none;
      margin-top: -1.5rem;
    }

    &::-moz-range-track {
      width: var(--input-range-range-width);
      height: var(--input-range-height);
      cursor: var(--input-range-cursor);
      box-shadow: var(--input-range-box-shadow);
      background: var(--input-range-background);
      border-radius: var(--input-range-border-radius);
      border: var(--input-range-border);
    }

    &::-moz-range-thumb {
      box-shadow: var(--input-range-thumb-box-shadow);
      border: var(--input-range-thumb-border);
      height: var(--input-range-thumb-height);
      width: var(--input-range-thumb-width);
      border-radius: var(--input-range-thumb-border-radius);
      background: var(--input-range-thumb-background) var(--input-range-svg-arrows) no-repeat center;
      cursor: var(--input-range-cursor);
    }

    &::-ms-track {
      width: var(--input-range-width);
      height: var(--input-range-height);
      cursor: var(--input-range-cursor);
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: var(--input-range-background);
      border: var(--input-range-border);
      border-radius: 2.6px;
      box-shadow: var(--input-range-box-shadow);
    }

    &::-ms-fill-upper {
      background: var(--input-range-background);
      border: var(--input-range-border);
      border-radius: 2.6px;
      box-shadow: var(--input-range-box-shadow);
    }

    &::-ms-thumb {
      box-shadow: var(--input-range-thumb-box-shadow);
      border: var(--input-range-thumb-border);
      height: var(--input-range-thumb-height);
      width: var(--input-range-thumb-width);
      border-radius: var(--input-range-thumb-border-radius);
      background: var(--input-range-thumb-background) var(--input-range-svg-arrows) no-repeat center;
    }
  }

</style>