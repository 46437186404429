<script>
    import SectionWrapper from "../components/SectionWrapper.svelte";
    import {onDestroy, onMount} from "svelte";
    import HeroStoryCard from "../components/HeroStoryCard.svelte";
    import StoryCard from "../components/StoryCard.svelte";
    import SectionHeading from "../components/SectionHeading.svelte";
    import {gsap} from "gsap";
    import ScrollTrigger from "gsap/dist/ScrollTrigger";
    import {
        closingVideoModal,
        openVideoModal,
        storiesGalleryIsVisible,
        videoEmbedCode
    } from "../stores/InformaAppStore.js";
    import StoriesGalleryModal from "$lib/components/StoriesGalleryModal.svelte";

    gsap.registerPlugin(ScrollTrigger);

    export let data;
    let tl;

    onMount(() => {

        let menuLink = document.querySelector("a[data-section='informa-stories']")

        tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#informa-stories",
                start: "top top",
                toggleActions: "play pause resume pause",
                invalidateOnRefresh: true,
                onToggle: ({isActive}) => {
                    !isActive ? document.activeElement.blur() : '';
                    if (menuLink === null) return;
                    isActive ? menuLink.classList.add('active') : menuLink.classList.remove('active')
                },
            }
        })
        tl.to('.informa-stories__heading', {
            opacity: 1,
            duration: 1,
            ease: "power2.out"
        })
    })

    onDestroy(() => {
        if (tl !== undefined) {
            tl.scrollTrigger.kill();
        }
    })

    const openStoriesGallery = () => {
        storiesGalleryIsVisible.update((n) => n === true ? false : true)
    }

    const playVideo = (event) => {
        // Set the values for the video
        if (event.detail.vimeoVideoID !== '' && $closingVideoModal === false) {
            videoEmbedCode.set(event.detail.vimeoVideoID)
            openVideoModal.set(true)
        }
    }

</script>

<SectionWrapper sectionName="informa-stories" sectionColor="bg-light-grey">
    <div class="informa-stories__wrapper">
        <div id="informa-stories-section" class="h-80"></div>
        <div class="informa-stories__heading">
            <SectionHeading
                    sectionHeading={data.sectionHeading}
            />
        </div>
        <div class="h-32"></div>
        <div class="container m-auto grid grid-cols-8 md:grid-cols-16 gap-16 sm:gap-24 md:gap-32 lg:gap-40">
            <div class="col-span-6 col-start-2 md:col-start-4 md:col-span-10 space-y-32">
                <!-- TODO: Finesse font sizes -->
                <div class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-16 sm:gap-24 md:gap-32 lg:gap-40 relative">
                    {#each data.sectionComponent.informaStories as {
                        videoTitle,
                        vimeoVideoID,
                        videoThumbnail,
                        jobTitle,
                        subjectQuote
                    }, i}
                        {#if i === 0}
                            <HeroStoryCard {videoTitle} {vimeoVideoID} {videoThumbnail} {jobTitle} {subjectQuote}/>
                        {:else}
                            <StoryCard {videoTitle} {vimeoVideoID} {videoThumbnail} {jobTitle} on:playVideo={playVideo} />
                        {/if}
                    {/each}
                </div>
                <div class="flex justify-center">
                    {#if data.ctaButtons}
                        {#each data.ctaButtons as buttons }
                            <button role="button" data-ui="btn-stories-gallery" on:click={openStoriesGallery}>{buttons.buttonLabel}</button>
                        {/each}
                    {/if}
                </div>
            </div>
        </div>
        <div class="h-32"></div>
    </div>
</SectionWrapper>
<StoriesGalleryModal data={data.sectionComponent.informaStories} />

<style lang="scss">

  .informa-stories {
    &__overlay {
      position: absolute;
      @apply inset-0 bg-light-grey;
      transform-origin: center;
      z-index: 2;
    }

    &__heading {
      opacity: 0;
      z-index: 3;
      position: relative;
    }
  }
</style>
