<script>
    import InformaIngredientCard from "../components/InformaIngredientCard.svelte";
    import SectionHeading from "../components/SectionHeading.svelte";
    import {onDestroy, onMount} from "svelte";
    import {gsap} from "gsap";
    import {openVideoModal, videoEmbedCode} from "../stores/InformaAppStore.js";
    import ScrollTrigger from "gsap/dist/ScrollTrigger";
    import HeroVideo from "../components/HeroVideo.svelte";

    export let data;
    let informaCards;
    let cards = [];
    let sectionHeading;
    let scrollingContent;
    let scrollTrigger;
    let scrollToPosition;
    let timeline;
    let totalWidth;
    let continueArrow;
    let vimeoVideoID = '936597694';

    const getTotalWidth = () => {
        let cardsWidth = document.querySelector('.informa-ingredients__cards').scrollWidth;
        let lineEls = document.querySelectorAll('.informa-ingredients__rounded-lines-wrapper, .informa-ingredients__video-wrapper');

        let linesWidth = 0;

        lineEls.forEach((el) => {
            linesWidth += el.offsetWidth;
        })

        return linesWidth + cardsWidth + 104;
    }

    onMount(() => {

        getTotalWidth();

        gsap.registerPlugin(ScrollTrigger);

        let mm = gsap.matchMedia();

        mm.add("(min-width: 768px)", (context) => {

            let menuLink = document.querySelector("a[data-section='informa-ingredients']")

            // Create the timeline
            timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: '#informa-ingredients',
                    pin: true,
                    scrub: true,
                    invalidateOnRefresh: true,
                    onToggle: ({isActive}) => {
                        !isActive ? document.activeElement.blur() : '';
                        isActive
                            ? menuLink.classList.add('active')
                            : menuLink.classList.remove('active');
                    },
                    end: () => `+=${getTotalWidth() * 0.85}`,
                },
            });

            // Add the animation to the timeline
            timeline.to(scrollingContent, {
                x: () => `-${getTotalWidth() - window.innerWidth}`,
                ease: 'none',
            })
                .to('.informa-ingredients__video-color-panel', {
                    height: '100vh',
                    y: () => `-${sectionHeading.scrollHeight}`,
                })
                .to('.informa-ingredients__video-content', {
                    opacity: 1,
                }, "<")
                .to('.splash-intro__arrow', {
                    opacity: 1,
                }, "<");

            return () => {
                console.log('Cleanup')
            }

        });


    });

    function openModal() {
        // Set the values for the video
        if (vimeoVideoID !== '') {
            videoEmbedCode.set(vimeoVideoID)
            openVideoModal.set(true)
        }
    }

    scrollToPosition = (event) => {

        const section = event.target;
        const sectionWidth = section.offsetWidth;
        const sectionLeft = section.offsetLeft;
        const containerWidth = totalWidth - window.innerWidth;
        const containerLeft = containerWidth / 2;
        const newPosition = -(sectionLeft - containerLeft + sectionWidth / 2);

        timeline.progress(newPosition);
    }

    onDestroy(() => {
        if (timeline !== undefined) {
            timeline.scrollTrigger.kill();
        }
    })

</script>
<a tabindex="0" aria-label="Informa Ingredients" data-section="informa-ingredients" class="js-access-section-link"
   href="#o"></a>
<section id="informa-ingredients" class="sm:h-screen">
    <div bind:this={sectionHeading} class="m-auto w-full py-64">
        <SectionHeading
                sectionHeading={data.sectionHeading}
                sectionParagraph={data.leadingText}
        />
    </div>
    <div bind:this={scrollingContent} class="flex flex-col sm:flex-row gap-32 sm:gap-64">
        <div class="informa-ingredients">

            <!-- Section Lines -->
            <div class="panel informa-ingredients__rounded-lines-wrapper">
                <div class="informa-ingredients__rounded-lines-inner-wrapper">
                    <div class="w-screen">
                        {#each data.sectionComponent.informaIngredients as {
                            cardColor,
                            ingredientLabel,
                            image,
                            standfirst,
                            copy
                        }}
                            <div class="bg-{cardColor} rounded-tl-full rounded-bl-full h-[25%] flex items-center">
                                <div class="py-24 pl-48 sm:pl-80">
                                    <span class="text-white text-36px font-aleo-regular">{ingredientLabel}</span>
                                </div>
                            </div>
                        {/each}
                    </div>
                </div>
            </div>

            <!-- Section Cards -->
            <div bind:this={informaCards} class="panel informa-ingredients__cards">
                {#each data.sectionComponent.informaIngredients as {
                    cardColor,
                    ingredientLabel,
                    image,
                    standfirst,
                    copy
                }, index}
                    <div bind:this={cards[index]} on:focus={scrollToPosition} tabindex="0"
                         aria-label="Informa {ingredientLabel}">
                        <InformaIngredientCard {cardColor} {ingredientLabel} {image} {standfirst} {copy}/>
                    </div>
                {/each}
            </div>

            <!-- Section Video -->

        </div>
        <div class="panel informa-ingredients__video-wrapper items-start">
            <div class="informa-ingredients__video-inner-wrapper flex items-center justify-center">
                <div class="informa-ingredients__video-content">
                    <h2 class="informa-ingredients__video-content-heading">Hear it from our people</h2>
                    <button class="informa-ingredients__video-play-button plain"
                            on:click={openModal}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 76 75.999">
                            <path id="Subtraction_16" data-name="Subtraction 16"
                                  d="M16692,76a37.825,37.825,0,0,1-21.246-6.49,38.086,38.086,0,0,1-13.766-16.719,37.963,37.963,0,0,1,3.5-36.037,38.107,38.107,0,0,1,16.719-13.768,37.942,37.942,0,0,1,36.037,3.5,38.108,38.108,0,0,1,13.77,16.719,37.947,37.947,0,0,1-3.5,36.037,38.135,38.135,0,0,1-16.719,13.768A37.763,37.763,0,0,1,16692,76Zm-9.758-55.459h0V55.972l30.813-17.715-30.812-17.717Z"
                                  transform="translate(-16654)" fill="#fff" opacity="0.5" />
                        </svg>
                    </button>
                </div>

                <div class="informa-ingredients__video-color-panel">
                    <div class="absolute bottom-32 z-[12] w-full">
                        <button bind:this={continueArrow}
                                class="secondary p-0 m-auto w-auto splash-intro__arrow sm:opacity-0 js-nav-link z-20"
                                data-section="informa-benefits" aria-label="Read informa benefits">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2" d="m19 9l-7 7l-7-7"/>
                            </svg>
                        </button>
                    </div>
                    <div class="absolute inset-0 bg-dark-grey z-10 opacity-70"></div>
                    <div class="relative h-full w-full bg-dark-grey">
                        <HeroVideo sectionVideo="ingredients"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<style lang="scss">
  .informa-ingredients {

    @apply gap-40 flex flex-col;

    @screen sm {
      @apply flex-row py-0;
    }

    &__video-inner-wrapper {

      background-color: var(--color-indigo);
      width: 100vw;
      height: 100vh;
      position: relative;


      @screen sm {
        height: 450px;
        display: flex;
      }

      @screen lg {
        height: 600px;
      }
    }

    &__video-color-panel {
      position: relative;
      @apply w-screen bg-coral absolute inset-0;
    }

    &__video-play-button {
      @apply z-20 mb-0 transition-all;
      width: 5rem;

      &:hover{
        transform: scale(1.1);
      }
    }

    &__video-content {
      @apply flex flex-col gap-16 items-center;
      z-index: 11;

      @screen sm {
        @apply opacity-0;
      }
    }

    &__video-content-heading {
      @apply text-20px font-aleo-light max-w-3xl m-auto text-white;

      @screen sm {
        @apply text-30px;
      }

      @screen lg {
        @apply text-36px;
      }
    }


    &__rounded-lines-wrapper {
      @apply flex flex-col items-end justify-end;
      @screen sm {
        height: 100%;
      }

      @screen md {
        &:first-of-type {
          //margin-left: 20vw;
        }
      }

      &:last-of-type {
        @apply items-start;
      }
    }

    &__rounded-lines-inner-wrapper {

      @apply hidden;

      @screen sm {
        height: 450px;
        display: flex;
        width: 50%;
      }

      @screen md {
        width: 25%;
      }

      @screen lg {
        height: 600px;
        width: 25%;
      }

      &--no-padding {
        @apply pl-0;
      }
    }

    &__cards {
      @apply flex flex-col gap-40 items-center px-16;

      @screen sm {
        @apply flex-row px-0;
      }
    }
  }
</style>
