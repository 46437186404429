<script>
    import SectionWrapper from "../components/SectionWrapper.svelte";
    import LinkedInPostcard from "../components/LinkedInPostcard.svelte";
    import SectionHeading from "../components/SectionHeading.svelte";
    import {Splide, SplideSlide} from '@splidejs/svelte-splide';
    import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';
    import '@splidejs/svelte-splide/css';
    import { onMount } from 'svelte';
    import viewport from '../../helpers/intersectionObserver';

    export let data;
    let socialSplider;
    let playSplide = () => {
        socialSplider.splide.Components.AutoScroll.play();
    }
    let pauseSplide = () => {
        socialSplider.splide.Components.AutoScroll.pause();
    }

    let splideOptions = {
        type: 'loop',
        perPage: 4,
        gap: "40px",
        arrows: false,
        pagination: false,
        focus  : 'center',
        fixedWidth: 320,
        mediaQuery: 'min',
        drag: false,
        autoplay: false,
        cloneStatus: false,
        autoScroll: {
            autoStart: false,
            speed: 0.9,
            pauseOnHover: false
        },
        breakpoints: {
            768: {
                fixedWidth: 300,
            },
            1920: {
                fixedWidth: 445,
            },
        }
    }

    onMount(() => {
        socialSplider.splide.Components.AutoScroll.pause();
    })

</script>

<SectionWrapper sectionName="social-media" sectionColor="bg-light-grey" sectionFullWidth="true">
    <div class="h-[100px] sm:h-[120px] md:h-[180px] lg:h-[220px]"></div>
    <div class="social-media-heading">
        <SectionHeading
                sectionHeading={data.sectionHeading}
                sectionParagraph={data.leadingText}
        />
    </div>
    <div class="h-32"></div>
    <div use:viewport
         on:enterViewport={() => playSplide()}
         on:exitViewport={() => pauseSplide()}>
        {#if data.hasOwnProperty('sectionComponent')}
            <Splide bind:this={socialSplider} options={ splideOptions } aria-label="Svelte Splide Example" extensions="{{AutoScroll}}">
                {#each data.sectionComponent.linkedinFeed as {postContent, postImage}}
                    <SplideSlide>
                        <LinkedInPostcard copy={postContent} image={postImage}/>
                    </SplideSlide>
                {/each}
            </Splide>
        {/if}
    </div>

    <div class="h-[100px] sm:h-[120px] md:h-[180px] lg:h-[220px]"></div>

</SectionWrapper>