<script>
    import SectionWrapper from "../components/SectionWrapper.svelte";
    import CareerLocationCard from "../components/CareerLocationCard.svelte";
    import SectionHeading from "../components/SectionHeading.svelte";
    import {onDestroy, onMount} from "svelte";
    import ScrollTrigger from "gsap/dist/ScrollTrigger";
    import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
    import {gsap} from "gsap";
    import ScrollContainerWrapper from "../components/ScrollContainerWrapper.svelte";
    import RangeSlider from "../components/RangeSlider.svelte";
    import decodeHtmlEntities from "../helpers/decodeHtmlEntities";

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    export let data;
    let rangeSliderName = 'careers-range-slider';
    let tl;

    let rangeSliderEl;
    let maxScrollValue = 0;
    let scrollContainerXValue = 0;

    let updateMaxScrollWidth = (event) => {
        maxScrollValue = event.detail
    }

    let moveSlider = (event) => {
        scrollContainerXValue = event.detail;
    }


    onMount(() => {


        // Now using GSAP

        let mm = gsap.matchMedia();

        mm.add("(max-width: 767px)", (context) => {

            gsap.to('.informa-location__heading', {
                opacity: 1,
                y: 0,
                scrollTrigger: {
                    trigger: ".informa-location__heading",
                    start: "top center",
                    toggleActions: "play pause resume reverse",
                }
            })

            gsap.to('.informa-location__map', {
                opacity: 0.1,
                scale: 1.2,
                scrollTrigger: {
                    trigger: ".informa-location__map",
                    start: "top center",
                    toggleActions: "play pause resume reverse",
                }
            })

            gsap.to('.informa-location__content', {
                opacity: 1,
                scrollTrigger: {
                    trigger: ".informa-location__content",
                    start: "top center",
                    toggleActions: "play pause resume reverse",
                }
            })

            return () => {
            }
        });

        mm.add("(min-width: 768px)", (context) => {

            let menuLink = document.querySelector("a[data-section='informa-careers']")

            tl = gsap.timeline({
                scrollTrigger: {
                    trigger: "#informa-careers",
                    start: "bottom bottom",
                    toggleActions: "play pause resume reverse",
                    scrub: 1,
                    pin: true,
                    onToggle: ({isActive}) => {
                        !isActive ? document.activeElement.blur() : '';
                        isActive ? menuLink.classList.add('active') : menuLink.classList.remove('active')
                    }
                }
            });

            tl.to('.informa-location__heading', {
                opacity: 1,
                y: 0,
            })

            tl.to('.informa-location__map', {
                opacity: 0.1,
                scale: 1.2,
            })

            tl.to('.informa-location__content', {
                opacity: 1,
            })

            tl.to('.informa-location__content', {
                opacity: 1,
            }, '+=5')

            return () => {
            }
        });

    })

    onDestroy(() => {
        if (tl !== undefined) {
            tl.scrollTrigger.kill();
        }
    })


</script>

<SectionWrapper sectionName="informa-careers" sectionColor="bg-light-grey" sectionFullWidth="true">

    <div class="informa-location__heading">
        <SectionHeading
                sectionHeading={data.sectionHeading}
                sectionParagraph={data.leadingText}
        />
    </div>

    <div class="h-24 sm:h-40 md:h-80"></div>

    <div class="informa-location__content opacity-0">
        <ScrollContainerWrapper
                bind:scrollContainerXValue={scrollContainerXValue}
                on:maxScrollWidthValueUpdated={updateMaxScrollWidth}
        >
            <div class="informa-location__cards">
                {#each data.sectionComponent['informa-careers'] as {heading, graphics, content}}
                    <CareerLocationCard {graphics} {heading} {content}/>
                {/each}
                <div class="informa-location__spacer-block"></div>
            </div>
        </ScrollContainerWrapper>

        <div class="h-24 md:h-40"></div>

        <div class="container m-auto px-32">
            <RangeSlider on:rangeValueUpdated={moveSlider} bind:inputMaxValue={maxScrollValue}
                         inputValue={scrollContainerXValue} bind:this={rangeSliderEl}
                         rangeSliderName={rangeSliderName}/>
        </div>

        <div class="h-24 sm:h-40 md:h-64"></div>

        <div class="text-center space-y-16">
            <p class="text-20px sm:text-30px lg:text-36px font-aleo-light">{@html decodeHtmlEntities(data.trailingText)}</p>
            <!-- Base -->
            {#each data.ctaButtons as buttons }
                <a href={buttons.buttonUrl} role="button" target="_blank">{buttons.buttonLabel}</a>
            {/each}
        </div>
    </div>

    <!-- background image-->
    <div class="absolute inset-0" slot="background">
        <div class="relative h-1/2 sm:h-full w-full">
            <img loading="lazy" class="informa-location__map h-full w-full opacity-10" src="/assets/images/world-map.svg" alt="">
        </div>
    </div>

</SectionWrapper>

<style lang="scss">

  .informa-location {
    &__cards {
      white-space: nowrap;
      transform: translateX(50vw);
      @apply flex gap-80 items-stretch justify-items-stretch px-16;
    }
  }

  .informa-location {
    &__heading {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      transform: translateY(100px);
    }

    &__map {
      transform: scale(0.8);
      transition: all 0.3s ease-in-out;
    }

    &__spacer-block {
      min-width: calc(50vw - var(--space-xxxxl));
    }
  }
</style>