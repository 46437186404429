<script>
    import SectionWrapper from "../components/SectionWrapper.svelte";
    import {onDestroy, onMount} from "svelte";

    import {gsap} from "gsap";
    import ScrollTrigger from "gsap/dist/ScrollTrigger";
    import HeroVideo from "../components/HeroVideo.svelte";
    import {eventStore, showFullHeader} from "../stores/InformaAppStore.js";

    gsap.registerPlugin(ScrollTrigger);

    let splashIntroArrow;
    let scrollTrigger;
    let tl = gsap.timeline();

    onMount(() => {

        tl.to("#informa-splash-video", {
            opacity: 1, duration: 1,
            startAt: {
                opacity: 0
            }
        });
        tl.to('.splash-intro__text', {
            opacity: 1, duration: 0.5, startAt: {
                opacity: 0
            }
        });
        tl.to('.splash-intro__button', {
            opacity: 1, duration: 0.5, startAt: {
                opacity: 0
            }
        });
        tl.to(splashIntroArrow, {
            opacity: 1, duration: 1.5,  y:'+=30',
        }, "<");

        scrollTrigger = ScrollTrigger.create({
            trigger: "#splash-intro-section",
            start: "top top",
            pinSpacing: false,
            pin: true,
            onEnterBack: () => {
                showFullHeader.set(true);
            },
            onLeave: () => {
                showFullHeader.set(false);
            },
        });

    });

    onDestroy(() => {
        if(scrollTrigger !== undefined){
            scrollTrigger.kill();
        }
    });

</script>

<SectionWrapper sectionName="splash-intro-section" sectionColor="bg-white" sectionContentPosition="items-end" sectionGSAPOverlap="true">
    <div class="text-content relative text-center p-4 z-10 text-white mb-48">
        <div id="splash-intro"></div>
        <div class="splash-intro__text flex flex-col sm:flex-row items-center gap-8 sm:gap-24 m-auto justify-center opacity-0">
            <p class="splash-intro__explore-text">Explore</p>
            <img class="splash-intro__life-at-svg" src="/assets/images/logo-life-at-informa--white.svg"
                 alt="Life at Informa">
        </div>
        <div class="h-32 lg:h-48"></div>
        <button class="splash-intro__button opacity-0 w-auto js-nav-link" data-section="informa-careers" role="button" aria-label="Explore jobs">Jobs</button>
        <div class="h-80"></div>
        <button bind:this={splashIntroArrow} class="secondary p-0 m-auto w-auto splash-intro__arrow opacity-0 js-nav-link" data-section="introduction" aria-label="Read introduction">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9l-7 7l-7-7"/></svg>
        </button>
    </div>

    <!-- background image-->
    <div class="absolute inset-0" slot="background" id="informa-splash-video">
        <div class="absolute inset-0 bg-dark-grey z-10 opacity-70"></div>

        <div class="relative h-full w-full mt-[5rem]">
            <HeroVideo sectionVideo="splash-intro" />
        </div>
    </div>
</SectionWrapper>

<style lang="scss">
  .splash-intro {
    &__explore-text {
      @apply text-36px pb-8 font-aleo-regular;

      @screen md {
        @apply text-50px;
      }

      @screen lg {
        font-size: 3.75rem;
      }
    }

    &__life-at-svg {
      width: 210px;
      @screen md {
        width: 238px;
      }
      @screen lg {
        width: 356px;
      }
    }

  }
</style>