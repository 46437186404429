<script>
    import {onMount} from "svelte";

    export let leadText = "";
    export let bigFact = "";
    export let trailingText = "";
    export let index = 0;
    export let image = '';
    export let imageAlt = '';

    const uniqId = (Math.random() * 10e15).toString(16);
    let isFocus = false;

    let y = 0;

    onMount(() => {

        window.addEventListener('scroll', () => {
            const element = document.getElementById(uniqId);

            if (element === null) return;

            // Access the component's element using `this`
            // Get the element you want to check

            // Get the position and size of the element relative to the viewport
            const rect = element.getBoundingClientRect();

            // Calculate the horizontal position of the element's center
            const elementCenter = rect.left + rect.width / 2;

            // Get the width of the viewport
            const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

            // Get the horizontal position of the middle of the viewport
            const viewportMiddle = viewportWidth / 2;
            const viewportMiddleStart = viewportMiddle - rect.width *2;
            const viewportMiddleEnd = viewportMiddle + rect.width *2;

            // Check if the element's center is within a certain distance of the middle of the viewport
            const distanceThreshold = rect.width; // adjust this value as needed
            const isInMiddle = Math.abs(elementCenter - viewportMiddle) < distanceThreshold;

            // Do something if the element is in the middle of the viewport
            if (isInMiddle) {
                isFocus = true;
            } else {
                isFocus = false;
            }

        })

    })
</script>

<svelte:window bind:scrollY={y} />
{#if index > 0}
<div class="informa-statistics__border"></div>
{/if}
<div class="informa-statistics__item {isFocus ? 'informa-statistics__item--in-focus' : '' }" id="{uniqId}">
    <div class="informa-statistics__item-inner">
        <img loading="lazy" class="informa-statistics__img" src="{image}" alt="{imageAlt}">
        {#if leadText !== ''}<p class="informa-statistics__lead-text">{@html leadText === '' ? '&nbsp;' : leadText}</p>{/if}
        {#if bigFact !== ''}<p class="informa-statistics__big-fact-text">{bigFact}</p>{/if}
        <p class="informa-statistics__trailing-text">{trailingText}</p>
    </div>
</div>

<style lang="scss">
  .informa-statistics {

    $self: &;

    &__item {
      @apply space-y-8 text-center transition-opacity duration-1000 flex items-center;
      width: 100%;
      min-width: 300px;

      &:first-of-type {
        border-left: none;
      }

      &--in-focus {
        opacity: 1;
      }
    }

    &__item-inner {
      margin: auto;
    }

    &__border {
      border-left: 1px solid var(--color-sky);
    }

    &__img {
      margin: 0 auto;
      + #{$self}__lead-text, + #{$self}__trailing-text {
        margin-top: var(--space-sm);
      }
    }

    &__lead-text {
      @apply text-20px lg:text-25px font-aleo-regular;
    }
    &__big-fact-text {
      @apply text-[50px] md:text-[70px] text-sky font-aleo-regular;
    }
    &__trailing-text {
      @apply text-20px lg:text-25px font-aleo-regular;
    }

  }
</style>