<script>
    import InformaBenefitGridItem from "../components/InformaBenefitGridItem.svelte";
    import SectionHeading from "../components/SectionHeading.svelte";
    import SectionWrapper from "../components/SectionWrapper.svelte"
    import {onDestroy, onMount} from "svelte";
    import {gsap} from "gsap";
    import ScrollTrigger from "gsap/dist/ScrollTrigger";

    export let data;
    let mm = gsap.matchMedia();
    let tl;
    gsap.registerPlugin(ScrollTrigger);

    onMount(() => {

        mm.add("(min-width: 768px)", (context) => {

            let benefitsWrapper = document.querySelector('#informa-benefits');
            let menuLink = document.querySelector("a[data-section='informa-benefits']")

            tl = gsap.timeline({
                scrollTrigger: {
                    trigger: benefitsWrapper,
                    start: "top top",
                    onToggle: ({isActive}) => {
                        isActive ? menuLink.classList.add('active') : menuLink.classList.remove('active')
                        !isActive ? document.activeElement.blur() : '';
                    },
                }
            });

            return () => {
            }
        });

    })

    onDestroy(() => {
        if (tl !== undefined) {
            tl.scrollTrigger.kill()
        }
    })

</script>
<SectionWrapper sectionName="informa-benefits" sectionColor="md:bg-light-grey" sectionFullWidth="true">
    <div class="benefits-wrapper z-10 relative">
        <div class="h-80"></div>

        <SectionHeading
                sectionHeading={data.sectionHeading}
                sectionParagraph={data.leadingText}
        />
        <div class="h-80"></div>

        <!--  Page grid -->
        <div id="benefits-grid" class="grid grid-cols-8 sm:grid-cols-12 md:grid-cols-16">
            <!-- Component Grid-->
            <div class="
            col-start-2 col-span-6 sm:col-span-8 sm:col-start-3 md:col-span-12 md:col-start-3 lg:col-start-4 lg:col-span-10
            grid grid-cols-1 gap-32 sm:grid-cols-2 sm:gap-x-16 sm:gap-y-64 md:gap-x-[96px] lg:gap-x-[115px]
            md:grid-cols-3
            ">
                {#each data.sectionComponent['informa-benefits'] as benefit}
                    <InformaBenefitGridItem
                            jsonPath={benefit.graphics}
                            heading={benefit.heading}
                            copy={benefit.content}
                    />
                {/each}
            </div>
        </div>

        <div class="h-80"></div>
    </div>
    <div class="benefits-background absolute bottom-0 left-0 right-0 bg-light-grey h-16 z-[1]"></div>
</SectionWrapper>
