<script>
    import {onMount} from "svelte";
    import decodeHtmlEntities from "../helpers/decodeHtmlEntities";

    export let graphics = "";
    export let heading = "";
    export let content = "";

    const uniqId = (Math.random() * 10e15).toString(16);
    let isVisible = false;

    let y = 0;

    onMount(() => {

    })
</script>

<article id="{uniqId}" class="location-card">
    <div class="grid grid-cols-1 sm:grid-cols-2">
        <div class="col-span-1 relative overflow-hidden aspect-video sm:aspect-[4/3.5]">
            <div class="inset-0 absolute">
                <img loading="lazy" class="object-cover h-full w-full" src="{graphics}" title="" alt="">
            </div>
        </div>
        <div class="col-span-1 p-16 lg:px-32 text-center space-y-8">
            <div class="text-20px lg:text-25px font-aleo-bold">{@html decodeHtmlEntities(heading)}</div>
            <p class="text-14px sm:text-16px lg:text-20px">{@html decodeHtmlEntities(content)}</p>
        </div>
    </div>
</article>

<style lang="scss">
  .location-card {

    $self: &;

    position: relative;
    white-space: normal;
    background-color: #FFF;
    min-width: 250px;

    @screen sm {
      min-width: 480px;
    }

    @screen lg {
      min-width: 580px;
    }

    &__overlay-content {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
    }

    &--in-middle {
      #{$self}__overlay-content {
        opacity: 1;
      }
    }
  }
</style>