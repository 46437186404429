<script>
    import {onMount, onDestroy} from "svelte";
    import { throttle } from '../helpers/throttle';
    import decodeHtmlEntities from "../helpers/decodeHtmlEntities";

    export let cardColor = 'coral';
    export let ingredientLabel = '';
    export let image = '';
    export let standfirst = '';
    export let copy = '';

    const uniqId = (Math.random() * 10e15).toString(16);

    let y = 0;



    onMount(() => {

        function isElementInMiddle(element) {
            const rect = element.getBoundingClientRect();
            const elementX = rect.left + (rect.width / 2);
            const elementY = rect.top + (rect.height / 2) + window.pageYOffset;

            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            const scrollX = window.pageXOffset;
            const scrollY = window.pageYOffset;

            const viewportX = scrollX + (viewportWidth / 2);
            const viewportY = scrollY + (viewportHeight / 2);

            let middle;

            if (window.matchMedia('(min-width: 768px)').matches) {
                const zoneleft = viewportX - (viewportWidth * 0.25);
                const zoneright = viewportX + (viewportWidth * 0.25);
                middle = elementX >= zoneleft && elementX <= zoneright;
            } else {
                const zoneTop = viewportY - (viewportHeight * 0.25);
                const zoneBottom = viewportY + (viewportHeight * 0.25);
                middle = elementY >= zoneTop && elementY <= zoneBottom;
            }

            return middle;
        }

        let currentFlipCard;

        let allFlipCards = document.querySelectorAll('.flip-card')

        const debouncedScrollHandler = () => {
            if (isElementInMiddle(currentFlipCard)) {

                allFlipCards.forEach((flipCard) => {
                    flipCard.classList.remove('flip-card--in-middle')
                })

                currentFlipCard.classList.add('flip-card--in-middle')
            } else {
                currentFlipCard.classList.remove('flip-card--in-middle')
            }
        };

        currentFlipCard = document.getElementById(uniqId);

        window.addEventListener('scroll', throttle(() => {
            debouncedScrollHandler(currentFlipCard)
        }, 250));
    });

    // onDestroy(() => {
    //     window.removeEventListener('scroll', debouncedScrollHandler);
    // });
</script>

<svelte:window bind:scrollY={y} />

<article id="{uniqId}" class="flip-card flip-card--{cardColor}">
    <div class="text-white space-y-16 flip-card__content">
        <div class="space-y-16">
            <h3 class="flip-card__heading">{ingredientLabel}</h3>
            <p class="flip-card__standfirst">{@html standfirst}</p>
        </div>
        <div class="flip-card__text-component space-y-8 lg:space-y-16">
            {@html decodeHtmlEntities(copy)}
        </div>
    </div>
    <div class="absolute inset-0 z-10 bg-sky flip-card__overlay-image">
        <div class="space-y-16 absolute flip-card__content">
            <h3 class="flip-card__heading flip-card__heading--reverse">{ingredientLabel}</h3>
        </div>
        <img loading="lazy" src="{image}" class="h-full w-full object-cover" alt="{ingredientLabel}">
    </div>
</article>

<style lang="scss">
  .flip-card {

    $self: &;

    @apply relative;

    @screen sm {
      min-width: 400px;
      height: 450px;
    }

    @screen lg {
      min-width: 600px;
      height: 600px;
    }

    &__overlay-image {
      transition: opacity 0.5s ease-in-out;
      opacity: 1;
    }

    &--in-middle {
        #{$self}__overlay-image {
            opacity: 0;
        }
    }

    &__heading {
      margin-left: -32px;
      padding-left: 32px;

      @apply text-25px font-aleo-bold bg-white;
      padding-right: 1.5rem;
      border-radius: 0 10rem 10rem 0;
      display: inline-block;

      @screen lg {
        @apply text-36px;
        margin-left: calc(var(--space-xxxl) * -1);
        padding-left: var(--space-xxxl);
      }
    }

    &__standfirst {
      @apply font-aleo-bold text-white text-20px;

      @screen lg {
        @apply text-25px;
      }
    }

    &__content {
      @apply py-16 px-32;

      @screen lg {
        @apply py-40 px-64;
      }
    }

    &__text-component {

      :global(p) {
        @apply text-16px;

        @screen lg {
          @apply text-20px;
        }
      }
    }

    &--coral {
      background-color: var(--color-coral);

      #{$self}__heading {
        color: var(--color-coral);

        &--reverse {
          background-color: var(--color-coral);
          color: var(--color-white);
        }
      }
    }

    &--sky {
      background-color: var(--color-sky);

      #{$self}__heading {
        color: var(--color-sky);

        &--reverse {
          background-color: var(--color-sky);
          color: var(--color-white);
        }
      }
    }

    &--cobalt {
      background-color: var(--color-cobalt);

      #{$self}__heading {
        color: var(--color-cobalt);

        &--reverse {
          background-color: var(--color-cobalt);
          color: var(--color-white);
        }
      }
    }

    &--lavender {
      background-color: var(--color-lavender);

      #{$self}__heading {
        color: var(--color-lavender);

        &--reverse {
          background-color: var(--color-lavender);
          color: var(--color-white);
        }
      }
    }

  }
</style>