<script>
    import {
        closingVideoModal,
        openVideoModal,
        storiesGalleryIsVisible,
        storiesModalVideoEmbedCode,
        videoEmbedCode
    } from "../stores/InformaAppStore.js";
    import { onMount } from "svelte";
    import {gsap} from "gsap";
    import * as focusTrap from 'focus-trap';
    import StoryCard from "$lib/components/StoryCard.svelte";
    import SectionHeading from "$lib/components/SectionHeading.svelte";
    import StoriesGalleryNavigation from "$lib/components/StoriesGalleryNavigation.svelte";


    let modalFocusTrap;
    let videoIframe;
    let docBody;

    let data = {};

    let tl = gsap.timeline({
        paused: true
    })

    $: openModal($storiesGalleryIsVisible)

    let openModal = value => {

        tl.reversed(!tl.reversed());

        if(modalFocusTrap !== undefined) {
            // Need to wait for the modal to animate in before activating the focus trap
            setTimeout(() => {
                value ? modalFocusTrap.activate() : modalFocusTrap.deactivate();
            }, 1000)
        }

        if(!value) return

        if(docBody !== undefined) {
            console.log('being called')

            docBody.classList.add('body-lock')
        }

    }

    onMount(async () => {

        docBody = document.querySelector('html');

        modalFocusTrap = focusTrap.createFocusTrap('.stories-modal__content', {
            initialFocus: () => document.querySelector('.stories-modal__content'),
            clickOutsideDeactivates: true,
            onDeactivate: () => {
                // closeModal()
            }
        });

        let res;

        if( import.meta.env.MODE === 'development') {
            res = await fetch('https://lifeat.test/api/videos');
        } else {
            res = await fetch('/api/videos');
        }
        data = await res.json();

        storiesModalVideoEmbedCode.set(data.informaStories[0].vimeoVideoID)

        let openModalAnimation = () => {

            tl.to('.stories-modal', {
                opacity: 1,
                display: 'block',
                duration: 0.35
            }).to('.video-container', {
                opacity: 1,
                duration: 0.35
            }).to('.stories-modal__close-btn', {
                scale: 1,
                duration: 0.35
            }).reverse();
        }

        openModalAnimation();
    })

    function closeModal(event) {
        storiesGalleryIsVisible.set(false)
        closingVideoModal.set(true)

        docBody = document.querySelector('html');
        docBody.classList.remove('body-lock')
        if(docBody !== undefined) {
            docBody.classList.remove('body-lock')
        }

        setTimeout(() => {
            storiesModalVideoEmbedCode.set(data.informaStories[0].vimeoVideoID)
            closingVideoModal.set(false)
        }, 500)
    }

</script>

<div aria-hidden="{!$storiesGalleryIsVisible}" data-ui="modal-stories-gallery" class="stories-modal { $storiesGalleryIsVisible ? 'stories-modal--open' : ''}"
     aria-describedby="videoModalDescription">
    <div class="sr-only" id="storiesModalDescription">
        This is a dialog window which overlays the main content of the page.
    </div>
    <div class="stories-modal__content space-y-40">
        <section class="relative">
            <button class="stories-modal__close-btn text-indigo" title='Close video story modal' on:click={closeModal}>
                <svg class="h-32 w-32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"/>
                </svg>
            </button>
            <SectionHeading
                    sectionHeading="Informa stories speak for themselves"
            />
        </section>

        <section class="grid sm:grid-cols-8 lg:grid-cols-6">
            <div class="video-container sm:col-start-2 sm:col-span-6 lg:col-start-2 lg:col-span-4">
                <div class="video-container__inner">
                    <iframe bind:this={videoIframe} title="" src="{$storiesModalVideoEmbedCode ? `https://player.vimeo.com/video/${$storiesModalVideoEmbedCode}`: ''}"
                            width="640" height="360"
                            frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen></iframe>
                </div>
            </div>

            <!-- Needed for accessiblity tabbing to work for the trap focus -->
            <a href="#"></a>

        </section>

        <StoriesGalleryNavigation data="{data.informaStories}" on:backButtonEvent={closeModal} />
    </div>
</div>

<style lang="scss">

  .stories-modal {
    background-color: var(--color-white);
    display: none;
    opacity: 0;
    @apply absolute inset-0 z-20 p-8;
    position: fixed;
    overflow-y: auto;

    @screen sm {
      @apply p-32;
    }

    &__content {
      @apply h-full w-full relative flex flex-col items-stretch max-w-screen-md m-auto;
    }

    &__close-btn {
      transform: scale(0);

      background-color: unset;
      border: unset;
      border-radius: unset;
      display: unset;
      padding: unset;
      margin-bottom: unset;
      width: unset;

      &:hover {
        transform: scale(1.2);
      }

      @screen sm {
        @apply absolute top-8 left-0;
      }
    }

  }

  .video-container {
    opacity: 0;
    border-radius: 0.25rem;
    @apply aspect-video;
    //@screen sm {
    //  @apply px-48;
    //}
  }

  .video-container__inner {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    position: relative;
  }

  //.video-container__inner::after {
  //  padding-top: 56.25%;
  //  display: block;
  //  content: '';
  //}

  .video-container__inner iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
